import {useEffect, useState} from "react";
import * as React from "react";
import Button from "./general/Button";
import Cookies from "js-cookie";
import {useRouter} from 'next/router';

export default function CurrentWorkable({auth}) {
    const [hasQuotation, setHasQuotation] = useState(false);
    const [quote, setQuote] = useState();
    const router = useRouter();

    useEffect(() => {
        const jwtToken = Cookies.get("auth");
        if (!jwtToken) {
            removeQuote();
        }

        if (typeof window !== "undefined") {
            const stringifiedQuote = localStorage.getItem("quotation");
            if (router.asPath.indexOf('/offerte/bewerken') > -1) {
                setHasQuotation(false);
            } else if (stringifiedQuote) {
                setQuote(JSON.parse(stringifiedQuote));
                setHasQuotation(true);
            }
        }

        if (!auth) {
            setHasQuotation(false);
        }

    }, []);

    const removeQuote = () => {
        if (typeof window !== "undefined") {
            localStorage.removeItem("quotation");
            setHasQuotation(false);
        }
    };

    return (
            <div className={`absolute top-[calc(100%_+_10px)] right-[4%] ${(hasQuotation && quote?.uid) ? 'block' : 'hidden'}`}>
                <Button
                    text="Terug naar offerte"
                    url={`/offerte/bewerken/${quote?.uid}`}
                    style={'primary'}
                />
            </div>
    );
}
