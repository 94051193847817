import Link from "next/link";
import { useState, useEffect } from "react";
import navLoggedOut from "../../public/content/navbar/nav-logged-out.json";
import navAdmin from "../../public/content/navbar/nav-admin.json";
import navUser from "../../public/content/navbar/nav-user.json";
import AuthServices from "../../services/Auth.services";
import { useRouter } from "next/router";
import MaxMegaMenu from "./MaxMegaMenu";
import ThirdPartyScripts from "../ThirdPartyScripts/ThirdPartyScripts";
import CurrentWorkable from "../CurrentWorkable";

function Navbar({ items = [], logo }) {
  const [navOpen, setNavOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    document.body.classList.contains("navOpen")
      ? document.body.classList.remove("navOpen")
      : document.body.classList.add("navOpen");
  }, [navOpen]);

  const [auth, setAuth] = useState(AuthServices.loggedIn());
  const [userData, setUserData] = useState();
  const [mainNav, setMainNav] = useState(navLoggedOut);

  useEffect(() => {
    switch (auth) {
      case true:
        userData?.role === "ADMIN" ? setMainNav(navAdmin) : setMainNav(navUser);
        setUserData(AuthServices.getUserData());
        break;
      default:
        setMainNav(navLoggedOut);
    }
  }, [auth]);

  const handleGoToRoute = async (e, vitemId) => {
    e.preventDefault();
    await router.push(`/producten?category=${vitemId}`, undefined, {
      shallow: true,
    });
    router.reload();
  };

  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }
  const showOrderNotification = () => {
    if (!auth || !userData?.last_ordered) {
      return <></>;
    }

    let dateOne = new Date();
    dateOne = subtractMonths(dateOne, 6);
    const dateTwo = new Date(userData?.last_ordered);

    if (dateTwo > dateOne) {
      return <></>;
    }

    return (
      <div className="bg-red-600 py-2 px-2 flex justify-center items-center w-full z-[500]">
        <p className="text-base text-white text-center">
          Je hebt meer dan 6 maanden niks besteld. Bestel zo snel mogelijk een{" "}
          <Link legacyBehavior href="/offertes">
            <a className="text-base text-white underline cursor-pointer">
              offerte
            </a>
          </Link>{" "}
          om je account te behouden.
        </p>
      </div>
    );
  };

  const showCurrentQuote = () => {
    return <CurrentWorkable />;
  };

  return (
    <>
      <ThirdPartyScripts />
      <div className={"relative mt-[108px] lg:mt-[70px]"}>
        {showOrderNotification()}
        {/* <div className="bg-olaf-orange-500 py-2 px-2 flex justify-center items-center">
           <p className="text-base text-white text-center">
             Let op! Vanwege gepland onderhoud is de website van Olaf Nijenkamp op woensdag 31 januari tussen 13.00 en 17.00 niet bereikbaar.
           </p>
         </div> */}
      </div>
      <div
        className="w-full fixed top-0 h-14 lg:h-auto bg-olaf-green-500 py-1.5 lg:py-4 z-[60]"
        id="navbar"
      >
        <div className="olafGrid h-full flex items-center justify-between">
          <div className="lg:order-4 space-x-4 flex">
            {userData?.role === "USER" && (
              <Link legacyBehavior href="/favorieten">
                <a className="flex-shrink-0">
                  <img
                    src="/assets/icon/heart-solid.svg"
                    alt="Profile picture placeholder"
                    width="24"
                    height="24"
                  />
                </a>
              </Link>
            )}
            <Link legacyBehavior href={"/profielen/" + userData?.user_id}>
              <a className="flex-shrink-0">
                <img
                  src="/assets/icon/pf-placeholder.svg"
                  alt="Profile picture placeholder"
                  width="24"
                  height="24"
                />
              </a>
            </Link>
          </div>
          <div className="lg:order-1 h-full pr-8 flex-shrink-0">
            <Link legacyBehavior href="/">
              <a>
                {userData?.company_logo ? (
                  <img
                    src={userData.company_logo}
                    alt={userData?.first_name + " logo"}
                    width="115"
                    height="100"
                    className="h-full max-h-12 w-auto max-w-[60%] lg:max-w-full object-contain mx-auto lg:mx-0"
                  />
                ) : (
                  <img
                    src="/assets/logo/olaf-main-logo.svg"
                    alt="Olaf Nijenkamp logo"
                    width="115"
                    height="100"
                    className="lg:h-12 lg:w-auto"
                  />
                )}
              </a>
            </Link>
          </div>
          <MaxMegaMenu />
          <nav className="hidden lg:block lg:order-2 lg:ml-8 lg:mr-8 flex-shrink-0">
            <ul className="flex lg:space-x-4 xl:space-x-8">
              {mainNav.map(({ url, title, logout }, i) => (
                <li key={i}>
                  <Link legacyBehavior href={url}>
                    <a
                      className={`text-white ${
                        router.pathname == url ? "underline" : ""
                      }`}
                      onClick={logout ? () => AuthServices.logout() : ""}
                    >
                      {title}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <CurrentWorkable auth={auth} />
          <div
            className="relative w-6 h-[18px] lg:hidden flex-shrink-0"
            onClick={() =>
              navOpen === true ? setNavOpen(false) : setNavOpen(true)
            }
          >
            <div className="absolute top-0 w-full h-[2px] bg-white rounded-full"></div>
            <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-[2px] bg-white rounded-full"></div>
            <div className="absolute top-full transform -translate-y-full w-full h-[2px] bg-white rounded-full"></div>
          </div>
        </div>
      </div>

      {!items.error && (
        <div className="hidden lg:block w-full h-14 lg:h-auto bg-olaf-green-500/20 relative z-50">
          <div className="olafGrid h-full flex items-center justify-center !hidden">
            <nav className="hidden lg:block">
              <ul className="flex lg:space-x-4 xl:space-x-8 z-10">
                {items?.map(({ name, vitemId, subcategories }, i) => (
                  <li key={i} className="relative group py-1.5 lg:py-4">
                    <a
                      className={`text-olaf-green-500 cursor-pointer block lowercase first-letter:uppercase ${
                        router.pathname == "/producten?category" + vitemId
                          ? "underline"
                          : ""
                      }`}
                      onClick={(e) => handleGoToRoute(e, vitemId)}
                    >
                      {name}
                    </a>
                    {subcategories && subcategories.length > 0 && (
                      <ul className="absolute left-0 -translate-x-6 bg-[#c4cbbe] top-full flex flex-col space-y-4 px-6 py-4 opacity-0 pointer-events-none transform -translate-y-2 group-hover:opacity-100 group-hover:pointer-events-auto group-hover:translate-y-0 transition duration-200 ease-in-out border-t-2 border-olaf-green-500">
                        {subcategories.map(({ name, vitemId }, i) => (
                          <a
                            key={i}
                            className={`text-olaf-green-500 whitespace-nowrap cursor-pointer ${
                              router.pathname ==
                              "/producten?category=" + vitemId
                                ? "underline"
                                : ""
                            }`}
                            onClick={(e) => handleGoToRoute(e, vitemId)}
                          >
                            {name}
                          </a>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div
              className="relative w-6 h-[18px] lg:hidden"
              onClick={() =>
                navOpen === true ? setNavOpen(false) : setNavOpen(true)
              }
            >
              <div className="absolute top-0 w-full h-[2px] bg-white rounded-full"></div>
              <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-[2px] bg-white rounded-full"></div>
              <div className="absolute top-full transform -translate-y-full w-full h-[2px] bg-white rounded-full"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
