import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import ToastService from "./Toast.Service";
import {data} from "autoprefixer";
import AbstractService from "./AbstractService";
const Cryptr = require('cryptr');


class AuthService {
  getUserData(token) {
    if (token) {
      return jwt_decode(token);
    } else if (Cookies.get("auth")) {
      return jwt_decode(Cookies.get("auth"));
    }
    return;
  }

  loggedIn() {
    if (!Cookies.get("auth")) {
      return;
    } else if (!this.getUserData().username.length) {
      return;
    }
    return true;
  }

  logout() {
    Cookies.remove("auth");

    if (typeof window !== "undefined") {
      localStorage.removeItem("quotation");
    }
    window.location.reload();
  }

  goToLogin() {
    window.location.href == "/inloggen";
  }

  cookieCheck(jwtToken = "", context, needsLogin = false, urlParam = "") {
    if (jwtToken === null || jwtToken === "" || jwtToken === "undefined") {
      const cookie = "auth=deleted;Max-Age=0;";
      context.res.setHeader("Set-Cookie", cookie);

      if (needsLogin) {
        const url = "/inloggen" + urlParam ? "?redirect=" + urlParam : "";

        return {
          redirect: {
            destination: url,
            permanent: false,
          },
        };
      }
    }
  }

  async refreshToken(jwtToken = "", context) {
    Date.prototype.addHours = function (h) {
      this.setHours(this.getHours() + h);
      return this;
    };

    const timestamp = new Date().addHours(1);

    if (jwtToken != null && jwtToken !== "" && jwtToken !== "undefined") {
      await fetch(process.env.NEXT_PUBLIC_CRAFT_BASE_URL + "/api/token/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => response)
        .then(async (data) => {
          return {
            ok: data.ok,
            data: await data.json(),
            status: data.status,
          };
        })
        .then((data) => {
          return data.data;
        })
        .then((data) => {
          if (data.token) {
            var hour = 1 / 24;

            Cookies.set("auth", data.token, {
              expires: hour,
              path: "/",
            });

            const cookie =
              "auth=" +
              data?.token +
              ";expires=" +
              timestamp.toUTCString() +
              ";path=/";
            context.res.setHeader("Set-Cookie", cookie);
          }
        });
    }
  }

  rememberLogin = (data) => {
    const cryptr = new Cryptr('TM7Q587K56eaBCCB2HeZ');
    const encrypted_username = cryptr.encrypt(data.username);
    const encrypted_password = cryptr.encrypt(data.password);
    const random_string = this.randomToken(52)

    const promise = fetch(
        process.env.NEXT_PUBLIC_CRAFT_BASE_URL +
        "/api/sessions/post",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: encrypted_username, password: encrypted_password, token: random_string,
          }),
        }).then((response) => response.json());

    Cookies.set('session_token', random_string, {
      expires: 30
    });
  }

  randomToken(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
    }
    return result;
  }

  async isArchived(jwtToken) {
    return AbstractService.getRequest(`/api/accounts/is-archived`, jwtToken);
  }

  getRemembered = async () => {

    const token = Cookies.get("session_token");

    if (!token) {
      return null;
    }

  return await fetch(
        process.env.NEXT_PUBLIC_CRAFT_BASE_URL +
        "/api/sessions/get?token=" + token,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then((response) => response)
        .then(async (data) => {
          return {
            ok: data.ok,
            data: await data.json(),
          };
        })
        .then((data) => {
              if (!data.ok) {
                throw data.data.message;
              }
              const cryptr = new Cryptr('TM7Q587K56eaBCCB2HeZ');
              const res = {
                "username": data.data.username,
                "password": cryptr.decrypt(data.data.password)
              }

              return res;
            }
        );
  }
}

export default new AuthService();
