import toast from "react-hot-toast";

class ToastService {
  loadToast(message = "Laden...", duration = 1800) {
    return toast.loading(message, {
      position: "bottom-center",
      duration: duration,
      style: {
        padding: "8px",
        fontSize: "1rem",
      },
    });
  }

  successToast(message, id = null) {
    return toast.success(message, {
      id: id,
      position: "bottom-center",
      iconTheme: {
        primary: "#58694C",
        secondary: "#fff",
      },
      duration: 1800,
    });
  }

  errorToast(message, id = null, duration = 1800) {
    return toast.error(message, {
      id: id,
      position: "bottom-center",
      iconTheme: {
        primary: "#8B0000",
        secondary: "#fff",
      },
      duration: duration,
      style: {
        padding: "8px",
        fontSize: "1rem",
      },
    });
  }

  promiseToast(
    promise,
    pending = "Laden...",
    success = "Geslaagd!",
    error = "Er is iets fout gegaan",
    duration = 3000
  ) {
    const toastOptions = {
      loading: pending,
      success: success,
      error: (err) => `Fout: ${err}`,
    };

    if (duration !== null) {
      toastOptions.duration = duration;
    }

    toast.promise(promise, toastOptions, {
      success: {
        iconTheme: {
          primary: "#58694C",
          secondary: "#fff",
        },
      },
      error: {
        iconTheme: {
          primary: "#8B0000",
          secondary: "#fff",
        },
        duration: duration,
      },
      position: "bottom-center",
      style: {
        padding: "16px",
        fontSize: "1rem",
      },
    });
  }
}

export default new ToastService();
