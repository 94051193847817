import { useEffect, useState } from "react";
import * as React from "react";
import Button from "./general/Button";
import Cookies from "js-cookie";
import {useRouter} from 'next/router';

export default function CurrentQuotation({positionOpen = 'bottom-0', positionClosed = '-bottom-[52px]' }) {
  const [offerteOpen, setOfferteOpen] = useState(false);
  const [hasQuotation, setHasQuotation] = useState(false);
  const [quote, setQuote] = useState();
  const router = useRouter();

  useEffect(() => {
    const jwtToken = Cookies.get("auth");
    if (!jwtToken) {
      removeQuote();
    }

    if (typeof window !== "undefined") {
      const stringifiedQuote = localStorage.getItem("quotation");
      if (router.asPath.indexOf('/offerte/bewerken') > -1) {
        setHasQuotation(false);
      } else if (stringifiedQuote) {
        setQuote(JSON.parse(stringifiedQuote));
        setHasQuotation(true);
      }
    }
  }, []);

  const toggle = () => {
    offerteOpen === true ? setOfferteOpen(false) : setOfferteOpen(true);
  };

  const removeQuote = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("quotation");
      setHasQuotation(false);
    }
  };

  return (
    <div className={"relative"}>
      {hasQuotation && quote && (
        <div
          className={`z-[99] fixed left-0 w-full bg-olaf-orange-500 transition-bottom duration-200 ease-in-out ${offerteOpen ? positionOpen : positionClosed}`}
        >
          <div
            className=" flex items-center olafGrid py-6 cursor-pointer"
            onClick={() => toggle()}
          >
            <img
              src="/assets/icon/chevron-up-white.svg"
              alt="Chevron up"
              className={`mt-1 transform transition duration-300 ease-in-out lg:w-5 lg:h-auto ${
                offerteOpen ? "rotate-180" : ""
              }`}
            />
            <div className="mx-auto">
              <p className="text-white text-lg font-semibold italic">
                Je bent offerte{" "}
                <span className={"font-bold text-lg underline"}>
                  {quote?.reference}
                </span>{" "}
                aan het bewerken
              </p>
            </div>
          </div>

          <div className="mb-3">
            <div className="flex items-center space-x-2 olafGrid">
              <button
                className={
                  "danger cursor-pointer w-full h-[40px] flex items-center justify-center rounded-tl-[10px] rounded-br-[10px] text-sm lg:text-base font-bold border-2 bg-[#8B0000] border-[#8B0000] text-[#fff]"
                }
                onClick={removeQuote}
              >
                Stop met toevoegen
              </button>
              <Button
                  text="Terug naar offerte"
                  url={`/offerte/bewerken/${quote?.uid}`}
                  style={'primary'}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
