import ToastService from "./Toast.Service";
import Router, {useRouter} from "next/router";

class AbstractService {

    async getRequest(route, jwtToken) {
        return await fetch(
            process.env.NEXT_PUBLIC_CRAFT_BASE_URL + route,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                }
            }
        ).then((response) => response)
            .then(async (response) => {
                if (!response.ok) {
                    throw response.data.message;
                }

                return response.json();
            });
    }

    async postRequest(route, jwtToken, data) {
        return await fetch(
            process.env.NEXT_PUBLIC_CRAFT_BASE_URL + route,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(data),
            }
        ).then((response) => response)
            .then(async (data) => {
                return {
                    ok: data.ok,
                    data: await data.json(),
                };
            })
            .then((data) => {
                if (!data.ok) {
                    throw data.data.message;
                }
            });
    }

    async deleteRequest(route, jwtToken) {
        return await fetch(
            process.env.NEXT_PUBLIC_CRAFT_BASE_URL + route,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                }
            }
        ).then((response) => response.json());
    }
}


export default new AbstractService();
