class ImageService {
  async getImage(id) {
    if (!id) {
      return null;
    }

    let images = await fetch(
      process.env.NEXT_PUBLIC_CRAFT_BASE_URL + "/api/images/detail/" + id,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json());

    if (images && images.length >= 1) {
      return images;
    }

    return null;
  }
}

export default new ImageService();
