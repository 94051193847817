export default function PriceDisplay({ price, option, style, discount = null}) {

    const priceElement = () => {
        const number = Number(price)
        return <>€{number.toFixed(2)}</>
    }

    const discountElement = (discount, price) => {

        if (!discount) {
            return ''
        }

        const total = (discount + price);

        return <span className={'line-through text-xs'}>€{total.toFixed(2)}</span>
    }

  if (option === "Groothandelprijs") {
    return (
      <span className={`text-red-700 ${style}`}>
       {discountElement(discount, price)} {priceElement()}
      </span>
    );
  }

  if (option === "Custom") {
    return <span className={`text-black ${style}`}>
       {discountElement(discount, price)} {priceElement()}
    </span>;
  }

    if (option === "normal") {
        return <span className={`text-olaf-green-700 ${style}`}>
       {discountElement(discount, price)} {priceElement()}
    </span>;
    }

  return (
    <span className={`text-[#00B82E] ${style}`}>
        {discountElement(discount, price)} {priceElement()}
    </span>
  );
}
