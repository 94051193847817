import Link from "next/link";

function Button({
  url = "",
  text,
  className,
  style,
  leftIcon,
  rightIcon,
  handleClick,
  target = "_self",
  disabled = false,
}) {
  const ancherElement = () => {
    return (
      <a
        className={`px-2 group cursor-pointer w-full h-[40px] flex items-center rounded-tl-[10px] rounded-br-[10px] text-sm lg:text-base font-bold border-2 transition duration-200 ease-in-out ${
          style === "primary"
            ? "bg-olaf-orange-500 text-white border-olaf-orange-500 hover:bg-white hover:text-olaf-orange-500"
            : "bg-transparent text-olaf-green-500 border-olaf-green-500 hover:bg-olaf-green-500 hover:text-white"
        } ${rightIcon ? "justify-start px-8" : "justify-center"} ${className} 
          ${
            style === "danger" &&
            "bg-olaf-red-100 border-olaf-red-100 text-white hover:text-olaf-red-100 hover:bg-transparent"
          }
        `}
        onClick={handleClick}
        target={target}
      >
        {leftIcon && (
          <img
            src={leftIcon}
            alt="Left icon"
            className="mr-4 h-2/5 w-auto transition duration-200 ease-in-out group-hover:brightness-0 group-hover:invert"
          />
        )}
        {text}
        {rightIcon && (
          <img
            src={rightIcon}
            alt="Right icon"
            className="ml-auto transition duration-200 ease-in-out group-hover:brightness-0	group-hover:invert"
          />
        )}
      </a>
    );
  };

  if (url === "") {
    return ancherElement();
  }

  return (
    <Link legacyBehavior href={url}>
      {ancherElement()}
    </Link>
  );
}

export default Button;
