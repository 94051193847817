import Link from "next/link";
import { useEffect, useState, useRef } from "react";
import AuthServices from "../../services/Auth.services";
import ImageServices from "../../services/Image.services";
import PriceDisplay from "../PriceDisplay";
import Button from "./Button";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

export default function MaxMegaMenu() {
  const [userInfo, setUserInfo] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    setUserInfo(AuthServices.getUserData());
  }, []);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }

    // Cleanup the event listener when component unmounts or input changes
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [inputRef]);

  const [searchInput, setSearchInput] = useState();
  const [isOpen, setIsOpen] = useState();
  const [products, setProducts] = useState();
  const [activeProduct, setActiveProduct] = useState();
  const [image, setImage] = useState("/assets/image/placeholder-logo.jpg");

  const getProducts = async () => {
    const jwtToken = Cookies.get("auth");
    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    if (jwtToken) {
      headers.append("Authorization", "Bearer " + jwtToken);
    }

    let producten = await fetch(
      process.env.NEXT_PUBLIC_CRAFT_BASE_URL +
        "/api/products?" +
        new URLSearchParams({
          limit: 25,
          page: 0,
          query: searchInput || "",
        }),
      {
        method: "get",
        headers: headers,
      }
    ).then((response) => response.json());

    setProducts(producten);
    setActiveProduct(producten[0]);
  };

  if (typeof window !== "undefined") {
    document.addEventListener("click", function (event) {
      if (event.target.closest(".canClick")) {
        return;
      }
      setIsOpen(false);
    });
  }


  const getProductsOnFilter = async () => {
    if (!searchInput) {
      setIsOpen(false);
      return;
    }
    if (searchInput.length > 2) {
      setIsOpen(true);

      await getProducts();
    } else {
      setIsOpen(false);
    }
  };

  const [timeoutCall, setTimeoutCall] = useState(null);
  const searchForProducts = () => {
    if (timeoutCall) {
      clearTimeout(timeoutCall);
    }

    setTimeoutCall(setTimeout(() => {
      getProductsOnFilter();
    }, 250));
  }

  useEffect(() => {
    findImage();
  }, [activeProduct]);

  const findImage = async () => {
    let imageId = null;

    if (activeProduct?.variant && activeProduct?.variant?.default_image_id) {
      imageId = activeProduct?.variant?.default_image_id;
    }

    if (
      activeProduct?.productImages &&
      activeProduct?.productImages.length > 0
    ) {
      imageId = activeProduct?.productImages[0]["assetId"] ?? null;
    }

    if (
      activeProduct?.variantImages &&
      activeProduct?.variantImages.length > 0
    ) {
      imageId = activeProduct?.variantImages[0]["vitumId"] ?? null;
    }

    if (imageId) {
      let images = await ImageServices.getImage(imageId);

      if (images && images.length > 0) {
        setImage(images[0]);
      } else {
        setImage("/assets/image/placeholder-logo.jpg");
      }
    } else {
      setImage("/assets/image/placeholder-logo.jpg");
    }
  };
  const router = useRouter();
  const handleGoToRoute = async (e, uid) => {
    e.preventDefault();
    await router.push(`${uid}`, undefined, {
      shallow: true,
    });
    router.reload();
  };
  const goToRoute = async (uid) => {
    await router.push(`${uid}`, undefined, {
      shallow: true,
    });
    router.reload();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      goToRoute("/producten?query=" + inputRef?.current?.value)
    }
  };

  return (
      <div
          className="bg-olaf-green-300 lg:bg-transparent lg:order-2 absolute top-full left-0 py-[10px] lg:py-0 w-screen lg:relative mx-auto xl:max-w-[600px] 2xl:max-w-[800px]">
          <div
              className={
                  "olafGrid lg:max-w-full block w-full border border-olaf-green-500 rounded-tl-lg rounded-br-lg bg-white h-[40px]"
              }
          >
              <div className={"flex w-full h-full px-4 lg:bg-transparent z-20 relative"}>
                  <input
                      placeholder={
                          "Direct zoeken op (Nederlandse) naam, maatvoering en kleur."
                      }
                      ref={inputRef}
                      className={"w-full h-full canClick"}
                      value={searchInput}
                      onKeyUp={searchForProducts}
                      onChange={(e) =>
                          setSearchInput(e.target.value)
                      }
                  />
                  <img
                      src="/assets/icon/search.svg"
                      alt="Search icon"
                      width="18"
                      height="18"
                  />
              </div>
              {isOpen && (
                  <div
                      className={
                          "mt-2 p-4 bg-white border border-olaf-green-500 rounded-tl-lg rounded-br-lg grid grid-cols-2 canClick"
                      }
                  >
                      <div className="mb-4 col-span-2">
                          <Button
                              url={`/producten?query=${searchInput}`}
                              text="Bekijk alle resultaten"
                              className="lg:hidden"
                          />
                      </div>
                      <div
                          className={"pr-2 col-span-2 lg:col-span-1 max-h-96 overflow-y-auto"}
                      >
                          {products?.map((a, i) => (
                              <a
                                  key={i}
                                  className="group cursor-pointer hover:bg-olaf-green-500/20 py-2 px-4 block"
                                  onMouseEnter={() => setActiveProduct(a)}
                                  onClick={(e) => handleGoToRoute(e, "/product/" + a.uid)}
                              >
                                  <p className="text-lg m-0">{a.name}</p>
                                  <p className="text-sm italic !mt-0">{a.dimension}</p>
                              </a>
                          ))}
                      </div>

                      {activeProduct ? (
                          <div
                              className={
                                  "hidden lg:block col-span-1 border-l pl-2 border-olaf-green-500"
                              }
                          >
                              <img
                                  src={image}
                                  alt="Placeholder"
                                  className="w-full h-52 object-cover object-center"
                              />
                              <p className="text-lg m-0">
                                  Latijn: {activeProduct?.name}
                                  {activeProduct?.dutch_name && (
                                      <span className="italic text-sm">
                    {" "}
                                          - {activeProduct?.dutch_name}
                  </span>
                                  )}
                              </p>
                              <p className="text-sm italic !mt-0">{activeProduct?.dimension}</p>

                              <p className="text-sm">
                                  Prijs:{" "}
                                  <PriceDisplay
                                      price={activeProduct?.price}
                                      option={userInfo?.price_display_option}
                                  />
                              </p>
                          </div>
                      ) : (
                          <div className="col-span-2 h-24 flex items-center justify-center">
                              Er zijn geen producten gevonden onder de zoekopdracht:{" "}
                              {searchInput}
                          </div>
                      )}
                      <a
                          className={`group cursor-pointer w-full h-[40px] flex items-center rounded-tl-[10px] rounded-br-[10px] text-sm lg:text-base font-bold border-2 transition duration-200 ease-in-out bg-transparent text-olaf-green-500 border-olaf-green-500 hover:bg-olaf-green-500 hover:text-white justify-center`}
                          onClick={(e) =>
                              handleGoToRoute(e, "/producten?query=" + searchInput)
                          }
                      >
                          Bekijk alle resultaten
                      </a>
                  </div>
              )}
          </div>
      </div>
  );
}
